import tableState from "../../templates/table/state";
const customizedTableState = tableState();
let date = new Date();
customizedTableState.filters.from = formatDate(
  new Date(new Date().setDate(date.getDate() - 90))
);
customizedTableState.filters.to = formatDate(date);

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export default {
  ...customizedTableState
};
